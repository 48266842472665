import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCT,
  RESET_PRODUCT,
  GET_ALL_PRODUCTS,
  ERROR_PRODUCT,
} from "../types/product_type";

const initialState = {
  products: null,
  page: null,
  pages: null,
  product: null,
  total_products: 0,
  loading: true,
  error: {},
  product_message: null,
  all_products: null,
};

export const product_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload.products,
        pages: payload.pages,
        page: payload.page,
        total_products: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        all_products: payload,
        loading: false,
        error: {},
      };

    case RESET_PRODUCT:
      return {
        ...state,
        product: null,
        loading: true,
        error: {},
      };

    case ADD_PRODUCT:
      return {
        ...state,
        product_message: payload,
        loading: false,
        error: {},
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: payload,
        loading: false,
        error: {},
      };
    case EDIT_PRODUCT:
      return {
        ...state,
        product_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_PRODUCT:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
            