import moment from "moment";

export const PAGE_TITLE = "Teams";
export const PAGE_SINGLE_TITLE = "Team";
export const LINK_URL = "teams";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  centre: {
    type: "related",
    required: true,
    title: "Centre",
    field: "name",
  },
};
export const initialValues = {
  name: "",
  centre: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Centre", value: "centre", related: true, field: "name" },
];
