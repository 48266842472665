import React from "react";
import { Bar } from "react-chartjs-2";

function BarChart({ countData }) {
  console.log("COUNT DATA", countData);
  const labelArray =
    countData &&
    countData.map((item) => {
      return item.month;
    });
  const dataArray =
    countData &&
    countData.map((item) => {
      return item.numberofdocuments;
    });
  console.log("DATA ARRAY", dataArray, labelArray);
  const data = {
    labels: labelArray,
    datasets: [
      {
        label: "Total Leads",
        data: dataArray,
        borderColor: [
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
        ],
        backgroundColor: [
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 206, 86, 0.8)",
        ],
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: "Bar Chart",
    },
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         min: 0,
    //         max: 10,
    //         stepSize: 1,
    //       },
    //     },
    //   ],
    // },
  };
  return <Bar data={data} options={options} />;
}

export default BarChart;
