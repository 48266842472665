import moment from "moment";

export const PAGE_TITLE = "Leadstageageings";
export const PAGE_SINGLE_TITLE = "Leadstageageing";
export const LINK_URL = "leadstageageings";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
    from_days: {
        type: "string",
        required: true,
        title: "From Days",
        inputType: "date",
    },
    to_days: {
        type: "string",
        required: true,
        title: "TO Days",
        inputType: "date",
    },
    color: {
        type: 'string',
        required: false,
        title: "Color",
        inputType: "color",
    }

};
export const initialValues = {
    from_days: "",
    to_days: "",
    color: ""
};

export const view_all_table = [
    { name: "From Date", value: "from_days" },
    { name: "To Date", value: "to_days" },
    { name: "Color", value: "color" },
];
