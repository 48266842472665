import {
  GET_PROCESSINGTYPES,
  ADD_PROCESSINGTYPE,
  EDIT_PROCESSINGTYPE,
  GET_PROCESSINGTYPE,
  RESET_PROCESSINGTYPE,
  GET_ALL_PROCESSINGTYPES,
  ERROR_PROCESSINGTYPE,
} from "../types/processingtype_type";

const initialState = {
  processingtypes: null,
  page: null,
  pages: null,
  processingtype: null,
  total_processingtypes: 0,
  loading: true,
  error: {},
  processingtype_message: null,
  all_processingtypes: null,
};

export const processingtype_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROCESSINGTYPES:
      return {
        ...state,
        processingtypes: payload.processingtypes,
        pages: payload.pages,
        page: payload.page,
        total_processingtypes: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_PROCESSINGTYPES:
      return {
        ...state,
        all_processingtypes: payload,
        loading: false,
        error: {},
      };

    case RESET_PROCESSINGTYPE:
      return {
        ...state,
        processingtype: null,
        loading: true,
        error: {},
      };

    case ADD_PROCESSINGTYPE:
      return {
        ...state,
        processingtype_message: payload,
        loading: false,
        error: {},
      };
    case GET_PROCESSINGTYPE:
      return {
        ...state,
        processingtype: payload,
        loading: false,
        error: {},
      };
    case EDIT_PROCESSINGTYPE:
      return {
        ...state,
        processingtype_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_PROCESSINGTYPE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
        