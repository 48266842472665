import {
  GET_TEAMS,
  ADD_TEAM,
  EDIT_TEAM,
  GET_TEAM,
  RESET_TEAM,
  GET_ALL_TEAMS,
  ERROR_TEAM,
} from "../types/team_type";

const initialState = {
  teams: null,
  page: null,
  pages: null,
  team: null,
  total_teams: 0,
  loading: true,
  error: {},
  team_message: null,
  all_teams: null,
};

export const team_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_TEAMS:
      return {
        ...state,
        teams: payload.teams,
        pages: payload.pages,
        page: payload.page,
        total_teams: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_TEAMS:
      return {
        ...state,
        all_teams: payload,
        loading: false,
        error: {},
      };

    case RESET_TEAM:
      return {
        ...state,
        team: null,
        loading: true,
        error: {},
      };

    case ADD_TEAM:
      return {
        ...state,
        team_message: payload,
        loading: false,
        error: {},
      };
    case GET_TEAM:
      return {
        ...state,
        team: payload,
        loading: false,
        error: {},
      };
    case EDIT_TEAM:
      return {
        ...state,
        team_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_TEAM:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
