import moment from "moment";

export const PAGE_TITLE = "Clients";
export const PAGE_SINGLE_TITLE = "Client";
export const LINK_URL = "clients";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",

    inputType: "text",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [{ name: "Name", value: "name" }];
