import api from "../../domain/api";
import {
  GET_FOLLOWUPS,
  ADD_FOLLOWUP,
  EDIT_FOLLOWUP,
  GET_FOLLOWUP,
  RESET_FOLLOWUP,
  GET_ALL_FOLLOWUPS,
  ERROR_FOLLOWUP,
  GET_LEAD_FOLLOWUPS,
} from "../types/followup_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addFollowup = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/followups`, formData);
    dispatch({
      type: ADD_FOLLOWUP,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getFollowups =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_FOLLOWUP,
      });
      const { data } = await api.get(
        `/followups?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_FOLLOWUPS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getFollowup = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_FOLLOWUP,
    });
    const { data } = await api.get(`/followups/${id}`);

    dispatch({
      type: GET_FOLLOWUP,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editFollowup = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/followups/${id}`, formData);
    dispatch({
      type: EDIT_FOLLOWUP,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteFollowup = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/followups/${id}`);
    dispatch(setAlert("Followup Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllFollowups = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/followups/all`);

    dispatch({
      type: GET_ALL_FOLLOWUPS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getFollowupByLead = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_FOLLOWUP,
    });
    const { data } = await api.get(`/followups/lead/${id}`);

    dispatch({
      type: GET_LEAD_FOLLOWUPS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_FOLLOWUP });
};
