import React, { useState } from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

function DoughnutChart({ products, count }) {
  console.log("PRODUCTS", products);
  console.log("COUNTS", count);
  const [labes, setLabes] = useState(null);
  const getTotalByID = (id) => {
    if (count) {
      console.log("PROCESSING TYPE COUNT", count);
      const filterData = count.filter((item) => item._id == id);
      if (filterData && filterData.length > 0) {
        return filterData[0].count;
      } else {
        return 0;
      }
    }
  };
  const mappedArray =
    products &&
    products.map((item) => {
      return item.name;
    });
  console.log("MAPPED ARRAY", mappedArray);
  const mappedCountArray =
    products &&
    products.map((item) => {
      return getTotalByID(item._id);
    });

  const data = {
    labels: mappedArray,
    datasets: [
      {
        label: "Sales 2020 (M)",
        data: mappedCountArray,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(143, 102, 255, 1)",
          "rgba(113, 102, 255, 1)",
          "rgba(123, 102, 255, 1)",
        ],
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: "Doughnut Chart",
    },
  };

  return <Doughnut data={data} options={options} />;
}

export default DoughnutChart;
