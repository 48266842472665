import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/leads_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleLead } from "../../shared/hooks/UseLead";
import FollowUpComponent from "./FollowUpComponent";
const ViewLead = ({ match }) => {
  const [data] = useSingleLead(match.params.id);
  const { loading, lead } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!loading ? (
          lead && (
            <>
              <SingleView
                data={lead}
                inputFields={inputFields}
                label={PAGE_SINGLE_TITLE}
                link={LINK_URL}
                id={lead._id}
              />
              <FollowUpComponent id={lead._id} />
            </>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewLead;
