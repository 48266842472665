import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboards } from "../../store/actions/dashboard_action";
import { useSelectAllStatus } from "./UseStatus";

// Add Data
export const useGetDashboard = () => {
  const dispatch = useDispatch();
  const [status] = useSelectAllStatus();
  const { all_statuss } = status;
  const data = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (all_statuss) {
      console.log("ALL STATUS", all_statuss);
      // activation, submission, rejection;
      const activation = getIDfromName(all_statuss, "Activation");
      const submission = getIDfromName(all_statuss, "Submission");
      const rejection = getIDfromName(all_statuss, "Rejection");
      dispatch(getDashboards(activation, submission, rejection));
    }
  }, [all_statuss]);
  return [data];
};

const getIDfromName = (data, field) => {
  const filterData = data.filter((item) => item.name == field);
  return filterData[0] && filterData[0]._id;
};
