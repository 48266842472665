import api from "../../domain/api";
import {
  GET_LEADSOURCES,
  ADD_LEADSOURCE,
  EDIT_LEADSOURCE,
  GET_LEADSOURCE,
  RESET_LEADSOURCE,
  GET_ALL_LEADSOURCES,
  ERROR_LEADSOURCE,
} from "../types/leadsource_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addLeadsource = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/leadsources`, formData);
    dispatch({
      type: ADD_LEADSOURCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getLeadsources =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_LEADSOURCE,
      });
      const { data } = await api.get(
        `/leadsources?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_LEADSOURCES,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getLeadsource = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_LEADSOURCE,
    });
    const { data } = await api.get(`/leadsources/${id}`);

    dispatch({
      type: GET_LEADSOURCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editLeadsource = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/leadsources/${id}`, formData);
    dispatch({
      type: EDIT_LEADSOURCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteLeadsource = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/leadsources/${id}`);
    dispatch(setAlert("Leadsource Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllLeadsources = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/leadsources/all`);

    dispatch({
      type: GET_ALL_LEADSOURCES,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_LEADSOURCE });
};
        