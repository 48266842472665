import {
  GET_LEADFEEDBACKTYPES,
  ADD_LEADFEEDBACKTYPE,
  EDIT_LEADFEEDBACKTYPE,
  GET_LEADFEEDBACKTYPE,
  RESET_LEADFEEDBACKTYPE,
  GET_ALL_LEADFEEDBACKTYPES,
  ERROR_LEADFEEDBACKTYPE,
} from "../types/leadfeedbacktype_type";

const initialState = {
  leadfeedbacktypes: null,
  page: null,
  pages: null,
  leadfeedbacktype: null,
  total_leadfeedbacktypes: 0,
  loading: true,
  error: {},
  leadfeedbacktype_message: null,
  all_leadfeedbacktypes: null,
};

export const leadfeedbacktype_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADFEEDBACKTYPES:
      return {
        ...state,
        leadfeedbacktypes: payload.leadfeedbacktypes,
        pages: payload.pages,
        page: payload.page,
        total_leadfeedbacktypes: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_LEADFEEDBACKTYPES:
      return {
        ...state,
        all_leadfeedbacktypes: payload,
        loading: false,
        error: {},
      };

    case RESET_LEADFEEDBACKTYPE:
      return {
        ...state,
        leadfeedbacktype: null,
        loading: true,
        error: {},
      };

    case ADD_LEADFEEDBACKTYPE:
      return {
        ...state,
        leadfeedbacktype_message: payload,
        loading: false,
        error: {},
      };
    case GET_LEADFEEDBACKTYPE:
      return {
        ...state,
        leadfeedbacktype: payload,
        loading: false,
        error: {},
      };
    case EDIT_LEADFEEDBACKTYPE:
      return {
        ...state,
        leadfeedbacktype_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_LEADFEEDBACKTYPE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
      