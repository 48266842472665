import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { upload } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/leads_enum";
import { useEffect } from "react";
import { useCreateLead, useAllLeads } from "../../shared/hooks/UseLead";
import { useSelectAllCentre } from "../../shared/hooks/UseCentre";
import { useSelectAllLeadfeedbacktype } from "../../shared/hooks/UseLeadfeedbacktype";
import { useSelectAllAgent } from "../../shared/hooks/UseAgent";
import { useSelectAllStatus } from "../../shared/hooks/UseStatus";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import { useSelectAllProcessingtype } from "../../shared/hooks/UseProcessingtype";
import { useSelectAllClient } from "../../shared/hooks/UseClient";
const AddLead = ({}) => {
  let history = useHistory();
  const [addData] = useCreateLead();
  const [centre] = useSelectAllCentre();
  const [client] = useSelectAllClient();

  const [leadfeedbacktype] = useSelectAllLeadfeedbacktype();
  const [agent] = useSelectAllAgent();
  const [status] = useSelectAllStatus();
  const [product] = useSelectAllProduct();
  const [processingtype] = useSelectAllProcessingtype();

  const [dropdownOptions, setDropdownOptions] = useState({});

  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);

  const submitFormClicked = async (values) => {
    // const selectedAgent = values.agent;
    await addData(values);
    history.push(`/${LINK_URL}`);
    // if (selectedAgent && agent) {
    //   const filterData =
    //     agent.all_agents &&
    //     agent.all_agents.filter((item) => item._id == selectedAgent);
    //   if (filterData && filterData.length > 0) {
    //     const checkData = filterData[0];
    //     values.centre = checkData.centre;
    //     values.team = checkData.team;
    //     console.log("CHECK DATA", checkData);
    //     values.agent = filterData[0];
    //     await addData(values);
    //     history.push(`/${LINK_URL}`);
    //   }
    // }
  };
  useEffect(() => {
    if (centre && centre.all_centres) {
      const newData = centre.all_centres.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, centre: newData });
    }
  }, [centre]);

  useEffect(() => {
    if (leadfeedbacktype && leadfeedbacktype.all_leadfeedbacktypes) {
      const newData = leadfeedbacktype.all_leadfeedbacktypes.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, lead_feedback_type: newData });
    }
  }, [leadfeedbacktype]);
  useEffect(() => {
    if (agent && agent.all_agents) {
      const newData = agent.all_agents.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, agent: newData });
    }
  }, [agent]);
  useEffect(() => {
    if (status && status.all_statuss) {
      const newData = status.all_statuss.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, status: newData });
    }
  }, [status]);
  useEffect(() => {
    if (product && product.all_products) {
      const newData = product.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, product: newData });
    }
  }, [product]);
  useEffect(() => {
    if (processingtype && processingtype.all_processingtypes) {
      const newData = processingtype.all_processingtypes.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, processing_type: newData });
    }
  }, [processingtype]);
  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, client: newData });
    }
  }, [client]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLead;
