import {
  GET_LEADSOURCES,
  ADD_LEADSOURCE,
  EDIT_LEADSOURCE,
  GET_LEADSOURCE,
  RESET_LEADSOURCE,
  GET_ALL_LEADSOURCES,
  ERROR_LEADSOURCE,
} from "../types/leadsource_type";

const initialState = {
  leadsources: null,
  page: null,
  pages: null,
  leadsource: null,
  total_leadsources: 0,
  loading: true,
  error: {},
  leadsource_message: null,
  all_leadsources: null,
};

export const leadsource_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADSOURCES:
      return {
        ...state,
        leadsources: payload.leadsources,
        pages: payload.pages,
        page: payload.page,
        total_leadsources: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_LEADSOURCES:
      return {
        ...state,
        all_leadsources: payload,
        loading: false,
        error: {},
      };

    case RESET_LEADSOURCE:
      return {
        ...state,
        leadsource: null,
        loading: true,
        error: {},
      };

    case ADD_LEADSOURCE:
      return {
        ...state,
        leadsource_message: payload,
        loading: false,
        error: {},
      };
    case GET_LEADSOURCE:
      return {
        ...state,
        leadsource: payload,
        loading: false,
        error: {},
      };
    case EDIT_LEADSOURCE:
      return {
        ...state,
        leadsource_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_LEADSOURCE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
          