import React from "react";
import { LIMIT, URI } from "../../domain/constant";
import TableDeleteBtn from "./TableDeleteBtn";
import TableEditBtn from "./TableEditBtn";
import TableViewBtn from "./TableViewBtn";
import Spinner from "../layout/Spinner";
import moment from "moment";

function DataTable({ loading, data, page, keys, deleteBtnClicked, field }) {
  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="desktop-only">
            <div className="table-responsive">
              <table className="table  align-middle  table-striped ">
                <thead>
                  <tr className="bg-transparent" role="row">
                    <th>#</th>
                    {keys &&
                      keys.map((item) => {
                        return <th> {item.name} </th>;
                      })}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading ? (
                    data &&
                    data.map((single, index) => {
                      return (
                        <tr key={`single-${index}`}>
                          <td className="sorting_1">
                            {index + 1 + (page - 1) * LIMIT}
                          </td>
                          {keys &&
                            keys.map((item) => {
                              if (item.image) {
                                return (
                                  <td>
                                    <img
                                      src={`${URI}${single[item.value]}`}
                                      style={{
                                        height: "100px",
                                        width: "auto",
                                        objectFit: "contain",
                                      }}
                                    />{" "}
                                  </td>
                                );
                              }
                              if (item.date) {
                                return (
                                  <td>
                                    {moment(single[item.value]).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                );
                              }
                              if (item.related && item.field) {
                                return (
                                  <td>
                                    {single[item.value] &&
                                      single[item.value][item.field]}
                                  </td>
                                );
                              }

                              return (
                                <td>
                                  {" "}
                                  {item.preFilled ? item.preFilled : ""}{" "}
                                  {single[item.value]}{" "}
                                </td>
                              );
                            })}
                          <td>
                            <TableViewBtn id={single._id} item={field} />
                            <TableEditBtn id={single._id} item={field} />
                            <TableDeleteBtn
                              id={single._id}
                              deleteBtnClicked={deleteBtnClicked}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={20} className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  {data && data.length === 0 && (
                    <tr>
                      <td colSpan={20}>No result found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DataTable;
