import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.css";
import "./assets/css/wysiwyg.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Login from "./containers/login/Login";
import setAuthToken from "./domain/setAuthToken";
import { loadUser } from "./store/actions/auth";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import Dashboard from "./containers/dashboard/Dashboard";
import PageNotFound from "./containers/notfound/PageNotFound";

import Profile from "./containers/profile/Profile";

import AddLead from "./containers/leads/AddLead";
import AllLeads from "./containers/leads/AllLeads";
import ViewLead from "./containers/leads/ViewLead";
import EditLead from "./containers/leads/EditLead";
import AddAgent from "./containers/agents/AddAgent";
import AllAgents from "./containers/agents/AllAgents";
import ViewAgent from "./containers/agents/ViewAgent";
import EditAgent from "./containers/agents/EditAgent";
import AddFollowup from "./containers/followups/AddFollowup";
import AllFollowups from "./containers/followups/AllFollowups";
import ViewFollowup from "./containers/followups/ViewFollowup";
import EditFollowup from "./containers/followups/EditFollowup";
import AddProduct from "./containers/products/AddProduct";
import AllProducts from "./containers/products/AllProducts";
import ViewProduct from "./containers/products/ViewProduct";
import EditProduct from "./containers/products/EditProduct";
import AddProcessingtype from "./containers/processingtypes/AddProcessingtype";
import AllProcessingtypes from "./containers/processingtypes/AllProcessingtypes";
import ViewProcessingtype from "./containers/processingtypes/ViewProcessingtype";
import EditProcessingtype from "./containers/processingtypes/EditProcessingtype";
import AddStatus from "./containers/status/AddStatus";
import AllStatuss from "./containers/status/AllStatuss";
import ViewStatus from "./containers/status/ViewStatus";
import EditStatus from "./containers/status/EditStatus";
import AddClient from "./containers/clients/AddClient";
import AllClients from "./containers/clients/AllClients";
import ViewClient from "./containers/clients/ViewClient";
import EditClient from "./containers/clients/EditClient";
import AddCentre from "./containers/centres/AddCentre";
import AllCentres from "./containers/centres/AllCentres";
import ViewCentre from "./containers/centres/ViewCentre";
import EditCentre from "./containers/centres/EditCentre";
import AddLeadsource from "./containers/leadsources/AddLeadsource";
import AllLeadsources from "./containers/leadsources/AllLeadsources";
import ViewLeadsource from "./containers/leadsources/ViewLeadsource";
import EditLeadsource from "./containers/leadsources/EditLeadsource";
import AddLeadfeedbacktype from "./containers/leadfeedbacktypes/AddLeadfeedbacktype";
import AllLeadfeedbacktypes from "./containers/leadfeedbacktypes/AllLeadfeedbacktypes";
import ViewLeadfeedbacktype from "./containers/leadfeedbacktypes/ViewLeadfeedbacktype";
import EditLeadfeedbacktype from "./containers/leadfeedbacktypes/EditLeadfeedbacktype";
import AddLeadstageageing from "./containers/leadstageageings/AddLeadstageageing";
import AllLeadstageageings from "./containers/leadstageageings/AllLeadstageageings";
import ViewLeadstageageing from "./containers/leadstageageings/ViewLeadstageageing";
import EditLeadstageageing from "./containers/leadstageageings/EditLeadstageageing";
import AddEmployee from "./containers/employees/AddEmployee";
import AllEmployees from "./containers/employees/AllEmployees";
import ViewEmployee from "./containers/employees/ViewEmployee";
import EditEmployee from "./containers/employees/EditEmployee";

import AddTeam from "./containers/teams/AddTeam";
import AllTeams from "./containers/teams/AllTeams";
import ViewTeam from "./containers/teams/ViewTeam";
import EditTeam from "./containers/teams/EditTeam";

function App() {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: "LOGOUT" });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Login} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/profile" component={Profile} />

          <PrivateRoutes exact path="/leads" component={AllLeads} />
          <PrivateRoutes exact path="/leads/add" component={AddLead} />
          <PrivateRoutes exact path="/leads/:id/view" component={ViewLead} />
          <PrivateRoutes exact path="/leads/:id/edit" component={EditLead} />

          <PrivateRoutes exact path="/agents" component={AllAgents} />
          <PrivateRoutes exact path="/agents/add" component={AddAgent} />
          <PrivateRoutes exact path="/agents/:id/view" component={ViewAgent} />
          <PrivateRoutes exact path="/agents/:id/edit" component={EditAgent} />

          <PrivateRoutes exact path="/followups" component={AllFollowups} />
          <PrivateRoutes
            exact
            path="/followups/add/:lead"
            component={AddFollowup}
          />
          <PrivateRoutes
            exact
            path="/followups/:id/view"
            component={ViewFollowup}
          />
          <PrivateRoutes
            exact
            path="/followups/:id/edit"
            component={EditFollowup}
          />

          <PrivateRoutes exact path="/products" component={AllProducts} />
          <PrivateRoutes exact path="/products/add" component={AddProduct} />
          <PrivateRoutes
            exact
            path="/products/:id/view"
            component={ViewProduct}
          />
          <PrivateRoutes
            exact
            path="/products/:id/edit"
            component={EditProduct}
          />
          <PrivateRoutes
            exact
            path="/processingtypes"
            component={AllProcessingtypes}
          />
          <PrivateRoutes
            exact
            path="/processingtypes/add"
            component={AddProcessingtype}
          />
          <PrivateRoutes
            exact
            path="/processingtypes/:id/view"
            component={ViewProcessingtype}
          />
          <PrivateRoutes
            exact
            path="/processingtypes/:id/edit"
            component={EditProcessingtype}
          />

          <PrivateRoutes exact path="/status" component={AllStatuss} />
          <PrivateRoutes exact path="/status/add" component={AddStatus} />
          <PrivateRoutes exact path="/status/:id/view" component={ViewStatus} />
          <PrivateRoutes exact path="/status/:id/edit" component={EditStatus} />
          <PrivateRoutes exact path="/clients" component={AllClients} />
          <PrivateRoutes exact path="/clients/add" component={AddClient} />
          <PrivateRoutes
            exact
            path="/clients/:id/view"
            component={ViewClient}
          />
          <PrivateRoutes
            exact
            path="/clients/:id/edit"
            component={EditClient}
          />

          <PrivateRoutes exact path="/centres" component={AllCentres} />
          <PrivateRoutes exact path="/centres/add" component={AddCentre} />
          <PrivateRoutes
            exact
            path="/centres/:id/view"
            component={ViewCentre}
          />
          <PrivateRoutes
            exact
            path="/centres/:id/edit"
            component={EditCentre}
          />

          <PrivateRoutes exact path="/leadsources" component={AllLeadsources} />
          <PrivateRoutes
            exact
            path="/leadsources/add"
            component={AddLeadsource}
          />
          <PrivateRoutes
            exact
            path="/leadsources/:id/view"
            component={ViewLeadsource}
          />
          <PrivateRoutes
            exact
            path="/leadsources/:id/edit"
            component={EditLeadsource}
          />

          <PrivateRoutes
            exact
            path="/leadfeedbacktypes"
            component={AllLeadfeedbacktypes}
          />
          <PrivateRoutes
            exact
            path="/leadfeedbacktypes/add"
            component={AddLeadfeedbacktype}
          />
          <PrivateRoutes
            exact
            path="/leadfeedbacktypes/:id/view"
            component={ViewLeadfeedbacktype}
          />
          <PrivateRoutes
            exact
            path="/leadfeedbacktypes/:id/edit"
            component={EditLeadfeedbacktype}
          />

          <PrivateRoutes
            exact
            path="/leadstageageings"
            component={AllLeadstageageings}
          />
          <PrivateRoutes
            exact
            path="/leadstageageings/add"
            component={AddLeadstageageing}
          />
          <PrivateRoutes
            exact
            path="/leadstageageings/:id/view"
            component={ViewLeadstageageing}
          />
          <PrivateRoutes
            exact
            path="/leadstageageings/:id/edit"
            component={EditLeadstageageing}
          />

          <PrivateRoutes exact path="/teams" component={AllTeams} />
          <PrivateRoutes exact path="/teams/add" component={AddTeam} />
          <PrivateRoutes exact path="/teams/:id/view" component={ViewTeam} />
          <PrivateRoutes exact path="/teams/:id/edit" component={EditTeam} />

          <Route exact path="/employees" component={AllEmployees} />
          <Route exact path="/employees/add" component={AddEmployee} />
          <Route exact path="/employees/:id/view" component={ViewEmployee} />
          <Route exact path="/employees/:id/edit" component={EditEmployee} />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
