import {
  GET_STATUSS,
  ADD_STATUS,
  EDIT_STATUS,
  GET_STATUS,
  RESET_STATUS,
  GET_ALL_STATUSS,
  ERROR_STATUS,
} from "../types/status_type";

const initialState = {
  statuss: null,
  page: null,
  pages: null,
  status: null,
  total_statuss: 0,
  loading: true,
  error: {},
  status_message: null,
  all_statuss: null,
};

export const status_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STATUSS:
      return {
        ...state,
        statuss: payload.statuss,
        pages: payload.pages,
        page: payload.page,
        total_statuss: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_STATUSS:
      return {
        ...state,
        all_statuss: payload,
        loading: false,
        error: {},
      };

    case RESET_STATUS:
      return {
        ...state,
        status: null,
        loading: true,
        error: {},
      };

    case ADD_STATUS:
      return {
        ...state,
        status_message: payload,
        loading: false,
        error: {},
      };
    case GET_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
        error: {},
      };
    case EDIT_STATUS:
      return {
        ...state,
        status_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_STATUS:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
                