import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { upload } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/followups_enum";
import {
  useUpdateFollowup,
  useSingleFollowup,
} from "../../shared/hooks/UseFollowup";
import { useSelectAllLead } from "../../shared/hooks/UseLead";
import { useSelectAllAgent } from "../../shared/hooks/UseAgent";
const EditFollowup = ({ match }) => {
  let history = useHistory();
  const [data] = useSingleFollowup(match.params.id);
  const [updateData] = useUpdateFollowup();
  const { loading, followup } = data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [lead] = useSelectAllLead();
  const [agent] = useSelectAllAgent();
  const [dropdownOptions, setDropdownOptions] = useState({});
  const submitFormClicked = async (values) => {
    if (featuredImage || gallery) {
      const images = await upload(featuredImage, gallery);
      if (images) {
        if (images.featured_image) {
          values.featured_image = images.featured_image;
        }
        if (images.gallery_images) {
          values.gallery = images.gallery_images;
        }
      }
    }
    await updateData(followup._id, values);

    history.push(`/leads`);
  };
  useEffect(() => {
    if (lead && lead.all_leads) {
      const newData = lead.all_leads.map((item) => {
        return {
          label: `${item.customer_name} (${item.phone})`,
          value: item._id,
        };
      });
      setDropdownOptions({ ...dropdownOptions, lead: newData });
    }
  }, [lead]);
  useEffect(() => {
    if (agent && agent.all_agents) {
      const newData = agent.all_agents.map((item) => {
        return {
          label: `${item.name}`,
          value: item._id,
        };
      });
      setDropdownOptions({ ...dropdownOptions, agent: newData });
    }
  }, [agent]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              followup && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {typeof followup[Object.keys(inputFields)[0]] !==
                          "object" &&
                          followup[Object.keys(inputFields)[0]]}{" "}
                        - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${followup._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={followup}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFollowup;
