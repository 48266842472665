import moment from "moment";

export const PAGE_TITLE = "Followups";
export const PAGE_SINGLE_TITLE = "Followup";
export const LINK_URL = "followups";
export const SEARCH_TERMS = ["follow_up_date"];
export const inputFields = {
  follow_up_date: {
    type: "string",
    required: true,
    title: "Follow Up Date",
    inputType: "date",
  },
  follow_up_time: {
    type: "string",
    required: false,
    title: "Follow Up Time",
    inputType: "time",
  },
  follow_up_remarks: {
    type: "string",
    required: false,
    title: "Follow Up Remarks",
  },
  status: {
    type: "select",
    required: false,
    title: "Status",
    options: ["Pending", "Called", "Not Picking", "Denied"],
  },
};
export const initialValues = {
  follow_up_date: "",
  follow_up_time: "",
  follow_up_remarks: "",
};

export const view_all_table = [
  { name: "Lead", value: "lead", related: true, field: "customer_name" },
  { name: "Phone", value: "lead", related: true, field: "phone" },
  { name: "Follow Up Date", value: "follow_up_date" },
  { name: "Follow Up Time", value: "follow_up_time" },
  { name: "Follow Up Remarks", value: "follow_up_remarks" },
  { name: "Agent", value: "agent", related: true, field: "name" },
  { name: "Status", value: "status" },
];
