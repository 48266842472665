import api from "../../domain/api";
import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCT,
  RESET_PRODUCT,
  GET_ALL_PRODUCTS,
  ERROR_PRODUCT,
} from "../types/product_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addProduct = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/products`, formData);
    dispatch({
      type: ADD_PRODUCT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getProducts =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_PRODUCT,
      });
      const { data } = await api.get(
        `/products?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_PRODUCTS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PRODUCT,
    });
    const { data } = await api.get(`/products/${id}`);

    dispatch({
      type: GET_PRODUCT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editProduct = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/products/${id}`, formData);
    dispatch({
      type: EDIT_PRODUCT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteProduct = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/products/${id}`);
    dispatch(setAlert("Product Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllProducts = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/products/all`);

    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_PRODUCT });
};
        