import {
  GET_LEADSTAGEAGEINGS,
  ADD_LEADSTAGEAGEING,
  EDIT_LEADSTAGEAGEING,
  GET_LEADSTAGEAGEING,
  RESET_LEADSTAGEAGEING,
  GET_ALL_LEADSTAGEAGEINGS,
  ERROR_LEADSTAGEAGEING,
} from "../types/leadstageageing_type";

const initialState = {
  leadstageageings: null,
  page: null,
  pages: null,
  leadstageageing: null,
  total_leadstageageings: 0,
  loading: true,
  error: {},
  leadstageageing_message: null,
  all_leadstageageings: null,
};

export const leadstageageing_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADSTAGEAGEINGS:
      return {
        ...state,
        leadstageageings: payload.leadstageageings,
        pages: payload.pages,
        page: payload.page,
        total_leadstageageings: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_LEADSTAGEAGEINGS:
      return {
        ...state,
        all_leadstageageings: payload,
        loading: false,
        error: {},
      };

    case RESET_LEADSTAGEAGEING:
      return {
        ...state,
        leadstageageing: null,
        loading: true,
        error: {},
      };

    case ADD_LEADSTAGEAGEING:
      return {
        ...state,
        leadstageageing_message: payload,
        loading: false,
        error: {},
      };
    case GET_LEADSTAGEAGEING:
      return {
        ...state,
        leadstageageing: payload,
        loading: false,
        error: {},
      };
    case EDIT_LEADSTAGEAGEING:
      return {
        ...state,
        leadstageageing_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_LEADSTAGEAGEING:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
