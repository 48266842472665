import api from "../../domain/api";
import {
  GET_TEAMS,
  ADD_TEAM,
  EDIT_TEAM,
  GET_TEAM,
  RESET_TEAM,
  GET_ALL_TEAMS,
  ERROR_TEAM,
} from "../types/team_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addTeam = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/teams`, formData);
    dispatch({
      type: ADD_TEAM,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getTeams =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_TEAM,
      });
      const { data } = await api.get(
        `/teams?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_TEAMS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getTeam = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_TEAM,
    });
    const { data } = await api.get(`/teams/${id}`);

    dispatch({
      type: GET_TEAM,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editTeam = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/teams/${id}`, formData);
    dispatch({
      type: EDIT_TEAM,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteTeam = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/teams/${id}`);
    dispatch(setAlert("Team Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllTeams = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/teams/all`);

    dispatch({
      type: GET_ALL_TEAMS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_TEAM });
};
