import api from "../../domain/api";
import {
  GET_STATUSS,
  ADD_STATUS,
  EDIT_STATUS,
  GET_STATUS,
  RESET_STATUS,
  GET_ALL_STATUSS,
  ERROR_STATUS,
} from "../types/status_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addStatus = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/statuss`, formData);
    dispatch({
      type: ADD_STATUS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getStatuss =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_STATUS,
      });
      const { data } = await api.get(
        `/statuss?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_STATUSS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getStatus = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_STATUS,
    });
    const { data } = await api.get(`/statuss/${id}`);

    dispatch({
      type: GET_STATUS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editStatus = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/statuss/${id}`, formData);
    dispatch({
      type: EDIT_STATUS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteStatus = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/statuss/${id}`);
    dispatch(setAlert("Status Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllStatuss = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/statuss/all`);

    dispatch({
      type: GET_ALL_STATUSS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_STATUS });
};
            