import api from "../../domain/api";
import {
  GET_CLIENTS,
  ADD_CLIENT,
  EDIT_CLIENT,
  GET_CLIENT,
  RESET_CLIENT,
  GET_ALL_CLIENTS,
  ERROR_CLIENT,
} from "../types/client_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addClient = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/clients`, formData);
    dispatch({
      type: ADD_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getClients =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_CLIENT,
      });
      const { data } = await api.get(
        `/clients?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_CLIENTS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_CLIENT,
    });
    const { data } = await api.get(`/clients/${id}`);

    dispatch({
      type: GET_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editClient = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/clients/${id}`, formData);
    dispatch({
      type: EDIT_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteClient = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/clients/${id}`);
    dispatch(setAlert("Client Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllClients = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/clients/all`);

    dispatch({
      type: GET_ALL_CLIENTS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_CLIENT });
};
      