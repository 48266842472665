import api from "../../domain/api";
import {
  GET_DASHBOARDS,
  RESET_DASHBOARD,
  ERROR_DASHBOARD,
} from "../types/dashboard_type";
import { handleError } from "../../shared/handleError";

export const getDashboards =
  (activation, submission, rejection) => async (dispatch) => {
    try {
      dispatch({
        type: RESET_DASHBOARD,
      });
      const { data } = await api.get(
        `/dashboards?activation_id=${activation}&submission_id=${submission}&rejection_id=${rejection}`
      );

      dispatch({
        type: GET_DASHBOARDS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_DASHBOARD });
};
