import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DoughnutChart from "../../components/charts/DonughtChart";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import DashboardImage from "../../assets/images/dashboard.png";
import ProjectImage from "../../assets/images/project-management.png";
import RecallImage from "../../assets/images/recall.png";
import SearchImage from "../../assets/images/search.png";
import { useAllLeads } from "../../shared/hooks/UseLead";
import { useGetDashboard } from "../../shared/hooks/UseDashboard";
import { useSelectAllStatus } from "../../shared/hooks/UseStatus";
import { useSelectAllLeadfeedbacktype } from "../../shared/hooks/UseLeadfeedbacktype";
import { useSelectAllProcessingtype } from "../../shared/hooks/UseProcessingtype";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import BarChart from "../../components/charts/BarChart";

const Dashboard = () => {
  const [
    data,
    pageNumber,
    setPageNumber,
    q,
    term,
    setTerm,
    termField,
    setTermField,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
    resetFilter,
  ] = useAllLeads();
  const [dashboards] = useGetDashboard();
  const [status] = useSelectAllStatus();
  const [feedback_type] = useSelectAllLeadfeedbacktype();
  const [processingtype] = useSelectAllProcessingtype();
  const [product] = useSelectAllProduct();

  const { loading, leads, total_leads, page, pages } = data;
  const { dashboard } = dashboards;

  console.log("DASHBOARDS", dashboards);

  const getTotalByID = (id) => {
    if (dashboard) {
      console.log(
        "PROCESSING TYPE COUNT",
        dashboard.lead_feedback_types_counts
      );
      const filterData = dashboard.lead_feedback_types_counts.filter(
        (item) => item._id == id
      );
      if (filterData && filterData.length > 0) {
        return filterData[0].count;
      } else {
        return 0;
      }
    }
  };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Dashboard"
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink="Main"
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div
                className="d-flex justify-content-between flex-column flex-md-row"
                style={{ gap: "20px" }}
              >
                <div className="dashboard-stat pending">
                  <img src={RecallImage} />

                  <div className="report-title">Callbacks</div>
                  <div className="report-stat">
                    {" "}
                    {dashboard && dashboard.followups
                      ? dashboard.followups
                      : "-"}{" "}
                  </div>
                </div>
                <div className="dashboard-stat pending">
                  <img src={RecallImage} />

                  <div className="report-title">Rejection</div>
                  <div className="report-stat">
                    {" "}
                    {dashboard && dashboard.total_rejections
                      ? dashboard.total_rejections
                      : "-"}{" "}
                  </div>
                </div>
                <div className="dashboard-stat pending">
                  <img src={RecallImage} />

                  <div className="report-title">Submission </div>
                  <div className="report-stat">
                    {" "}
                    {dashboard && dashboard.total_submissions
                      ? dashboard.total_submissions
                      : "-"}{" "}
                  </div>
                </div>
                <div className="dashboard-stat pending">
                  <img src={RecallImage} />

                  <div className="report-title">Today’s Activation </div>
                  <div className="report-stat">
                    {" "}
                    {dashboard && dashboard.followups
                      ? dashboard.followups
                      : "-"}{" "}
                  </div>
                </div>
                <div className="dashboard-stat pending">
                  <img src={RecallImage} />

                  <div className="report-title">Total Activation</div>
                  <div className="report-stat">
                    {" "}
                    {dashboard && dashboard.total_activations
                      ? dashboard.total_activations
                      : "-"}{" "}
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Fresh Leads</div>
                    </div>
                    <div className="card-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Customer Name</th>
                            <th> Phone</th>
                            <th> Product</th>
                            <th>Processing Type </th>
                            <th>Feedback Type </th>
                            <th>Centre</th>
                            <th>Agent</th>
                            <th>Status</th>
                            <th> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {leads &&
                            leads.map((item, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.customer_name}</td>
                                  <td> {item.phone}</td>
                                  <td> {item.product && item.product.name}</td>
                                  <td>
                                    {item.processing_type &&
                                      item.processing_type.name}
                                  </td>
                                  <td>
                                    {item.lead_feedback_type &&
                                      item.lead_feedback_type.name}
                                  </td>
                                  <td> {item.centre && item.centre.name}</td>
                                  <td>{item.agent && item.agent.name}</td>
                                  <td>{item.status && item.status.name}</td>
                                  <td>
                                    <Link to={`/leads/${item._id}/view`}>
                                      <i className="fa fa-eye"></i>{" "}
                                    </Link>{" "}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
