import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { upload } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/followups_enum";
import { useEffect } from "react";
import {
  useCreateFollowup,
  useAllFollowups,
} from "../../shared/hooks/UseFollowup";
import { useSelectAllLead } from "../../shared/hooks/UseLead";
import { useSelectAllAgent } from "../../shared/hooks/UseAgent";
const AddFollowup = ({ match }) => {
  let history = useHistory();
  const [addData] = useCreateFollowup();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [lead] = useSelectAllLead();
  const [agent] = useSelectAllAgent();
  const [dropdownOptions, setDropdownOptions] = useState({});
  const submitFormClicked = async (values) => {
    values.lead = match.params.lead;
    await addData(values);
    history.push(`/leads`);
  };
  useEffect(() => {
    if (lead && lead.all_leads) {
      const newData = lead.all_leads.map((item) => {
        return {
          label: `${item.customer_name} (${item.phone})`,
          value: item._id,
        };
      });
      setDropdownOptions({ ...dropdownOptions, lead: newData });
    }
  }, [lead]);
  useEffect(() => {
    if (agent && agent.all_agents) {
      const newData = agent.all_agents.map((item) => {
        return {
          label: `${item.name}`,
          value: item._id,
        };
      });
      setDropdownOptions({ ...dropdownOptions, agent: newData });
    }
  }, [agent]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFollowup;
