import {
  GET_DASHBOARDS,
  RESET_DASHBOARD,
  ERROR_DASHBOARD,
} from "../types/dashboard_type";

const initialState = {
  dashboard: null,

  loading: true,
  error: {},
};

export const dashboard_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DASHBOARDS:
      return {
        ...state,
        dashboard: payload,
        loading: false,
        error: {},
      };

    case RESET_DASHBOARD:
      return {
        ...state,
        dashboard: null,
        loading: true,
        error: {},
      };

    case ERROR_DASHBOARD:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
