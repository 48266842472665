import moment from "moment";
import { ROLES } from "../../domain/constant";
export const PAGE_TITLE = "Agents";
export const PAGE_SINGLE_TITLE = "Agent";
export const LINK_URL = "agents";
export const SEARCH_TERMS = ["name", "meta_title"];

export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
  },
  phone: {
    type: "string",
    required: true,
    title: "phone",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
  },
  username: {
    type: "string",
    required: true,
    title: "Username",
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    // hide: true,
    inputType: "password",
    hideOnView: true,
    hideOnUpdate: true,
  },
  address: {
    type: "string",
    required: true,
    title: "Address",
    inputType: "text",
  },
  emergency_contact_no: {
    type: "string",
    required: true,
    title: "Emergency Contact No",
  },

  team: {
    type: "related",
    required: false,
    title: "Team",
    field: "name",
  },

  centre: {
    type: "related",
    required: false,
    title: "Centre",
    field: "name",
  },
  role: {
    type: "select",
    required: true,
    title: "Agent Role",
    options: ROLES,
  },
};
export const initialValues = {
  name: "",
  phone: "",
  address: "",
  emergency_contact_no: "",
  reporting_manager: "",
  centre: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Username", value: "username" },
  { name: "Role", value: "role" },
  { name: "Phone", value: "phone" },

  {
    name: "Team",
    value: "team",
    related: true,
    field: "name",
  },
  {
    name: "Centre",
    value: "centre",
    related: true,
    field: "name",
  },
];
