export const PAGE_TITLE = "Follow Ups";
export const PAGE_SINGLE_TITLE = "Follow Up";
export const LINK_URL = "leads";
export const SEARCH_TERMS = ["customer_name", "phone", "agent"];
export const inputFields = {
  lead_date: {
    type: "string",
    required: true,
    title: "Lead Date",
    inputType: "date",
  },
  customer_name: {
    type: "string",
    required: true,
    title: "Customer Name",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
  },
  alt_phone: {
    type: "string",
    required: false,
    title: "Alt Phone",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
  },
  product: {
    type: "related",
    required: true,
    title: "Product",
    field: "name",
  },
  processing_type: {
    type: "related",
    required: false,
    title: "Processing Type",
    field: "name",
  },
  status: {
    type: "related",
    required: false,
    title: "Status",
    field: "name",
  },
  remarks: {
    type: "text",
    required: false,
    title: "remarks",
    inputType: "text",
  },
  // agent: {
  //   type: "related",
  //   required: true,
  //   title: "Agent",
  //   field: "name",
  // },
  lead_feedback_type: {
    type: "related",
    required: false,
    title: "Lead Feedback Type",
    field: "name",
  },

  divider: {
    type: "divider",
    required: false,
    title: "Follow Up Details",
    hideOnUpdate: true,
  },
  follow_up_date: {
    type: "string",
    required: false,
    title: "Follow Up Date",
    inputType: "date",
    hideOnView: true,
    hideOnUpdate: true,
  },
  follow_up_time: {
    type: "string",
    required: false,
    title: "Follow Up Time",
    inputType: "time",
    hideOnView: true,
    hideOnUpdate: true,
  },
  follow_up_remarks: {
    type: "string",
    required: false,
    title: "Follow Up Remarks",
    hideOnView: true,
    hideOnUpdate: true,
  },
};
export const initialValues = {
  customer_name: "",
  phone: "",
  alt_phone: "",
  email: "",
  product: "",
  processing_type: "",
  status: "",
  remarks: "",
  lead_feedback_type: "",
  centre: "",
  client: "",
};

export const view_all_table = [
  { name: "Lead Date", value: "lead_date" },
  { name: "Customer Name", value: "customer_name" },
  { name: "Phone", value: "phone" },
  { name: "Product", value: "product", related: true, field: "name" },
  {
    name: "Processing Type",
    value: "processing_type",
    related: true,
    field: "name",
  },
  { name: "Centre", value: "centre", related: true, field: "name" },
  { name: "Agent", value: "agent", related: true, field: "name" },
  { name: "Team", value: "team", related: true, field: "name" },
  {
    name: "Feedback Type",
    value: "lead_feedback_type",
    related: true,
    field: "name",
  },
  { name: "Status", value: "status", related: true, field: "name" },
];
