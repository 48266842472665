import {
  GET_LEADS,
  ADD_LEAD,
  EDIT_LEAD,
  GET_LEAD,
  RESET_LEAD,
  GET_ALL_LEADS,
  ERROR_LEAD,
} from "../types/lead_type";

const initialState = {
  leads: null,
  page: null,
  pages: null,
  lead: null,
  total_leads: 0,
  loading: true,
  error: {},
  lead_message: null,
  all_leads: null,
};

export const lead_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADS:
      return {
        ...state,
        leads: payload.leads,
        pages: payload.pages,
        page: payload.page,
        total_leads: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_LEADS:
      return {
        ...state,
        all_leads: payload,
        loading: false,
        error: {},
      };

    case RESET_LEAD:
      return {
        ...state,
        lead: null,
        loading: true,
        error: {},
      };

    case ADD_LEAD:
      return {
        ...state,
        lead_message: payload,
        loading: false,
        error: {},
      };
    case GET_LEAD:
      return {
        ...state,
        lead: payload,
        loading: false,
        error: {},
      };
    case EDIT_LEAD:
      return {
        ...state,
        lead_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_LEAD:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
        