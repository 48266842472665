import {
  GET_CENTRES,
  ADD_CENTRE,
  EDIT_CENTRE,
  GET_CENTRE,
  RESET_CENTRE,
  GET_ALL_CENTRES,
  ERROR_CENTRE,
} from "../types/centre_type";

const initialState = {
  centres: null,
  page: null,
  pages: null,
  centre: null,
  total_centres: 0,
  loading: true,
  error: {},
  centre_message: null,
  all_centres: null,
};

export const centre_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CENTRES:
      return {
        ...state,
        centres: payload.centres,
        pages: payload.pages,
        page: payload.page,
        total_centres: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_CENTRES:
      return {
        ...state,
        all_centres: payload,
        loading: false,
        error: {},
      };

    case RESET_CENTRE:
      return {
        ...state,
        centre: null,
        loading: true,
        error: {},
      };

    case ADD_CENTRE:
      return {
        ...state,
        centre_message: payload,
        loading: false,
        error: {},
      };
    case GET_CENTRE:
      return {
        ...state,
        centre: payload,
        loading: false,
        error: {},
      };
    case EDIT_CENTRE:
      return {
        ...state,
        centre_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_CENTRE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
        