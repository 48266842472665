import moment from "moment";
export const PAGE_TITLE = "Status";
export const PAGE_SINGLE_TITLE = "Status";
export const LINK_URL = "Status";
export const SEARCH_TERMS = ["name"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [{ name: "Name", value: "name" }];
