import {
  GET_CLIENTS,
  ADD_CLIENT,
  EDIT_CLIENT,
  GET_CLIENT,
  RESET_CLIENT,
  GET_ALL_CLIENTS,
  ERROR_CLIENT,
} from "../types/client_type";

const initialState = {
  clients: null,
  page: null,
  pages: null,
  client: null,
  total_clients: 0,
  loading: true,
  error: {},
  client_message: null,
  all_clients: null,
};

export const client_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: payload.clients,
        pages: payload.pages,
        page: payload.page,
        total_clients: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_CLIENTS:
      return {
        ...state,
        all_clients: payload,
        loading: false,
        error: {},
      };

    case RESET_CLIENT:
      return {
        ...state,
        client: null,
        loading: true,
        error: {},
      };

    case ADD_CLIENT:
      return {
        ...state,
        client_message: payload,
        loading: false,
        error: {},
      };
    case GET_CLIENT:
      return {
        ...state,
        client: payload,
        loading: false,
        error: {},
      };
    case EDIT_CLIENT:
      return {
        ...state,
        client_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_CLIENT:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
        