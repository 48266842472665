import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { upload } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/leads_enum";
import { useUpdateLead, useSingleLead } from "../../shared/hooks/UseLead";
import { useSelectAllCentre } from "../../shared/hooks/UseCentre";
import { useSelectAllLeadsource } from "../../shared/hooks/UseLeadsource";
import { useSelectAllLeadfeedbacktype } from "../../shared/hooks/UseLeadfeedbacktype";
import { useSelectAllAgent } from "../../shared/hooks/UseAgent";
import { useSelectAllStatus } from "../../shared/hooks/UseStatus";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import { useSelectAllProcessingtype } from "../../shared/hooks/UseProcessingtype";
import { useSelectAllClient } from "../../shared/hooks/UseClient";
const EditLead = ({ match }) => {
  let history = useHistory();
  const [data] = useSingleLead(match.params.id);
  const [updateData] = useUpdateLead();
  const { loading, lead } = data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [centre] = useSelectAllCentre();
  const [client] = useSelectAllClient();
  const [leadsource] = useSelectAllLeadsource();
  const [leadfeedbacktype] = useSelectAllLeadfeedbacktype();
  const [agent] = useSelectAllAgent();
  const [status] = useSelectAllStatus();
  const [product] = useSelectAllProduct();
  const [processingtype] = useSelectAllProcessingtype();

  const [dropdownOptions, setDropdownOptions] = useState({});
  const submitFormClicked = async (values) => {
    if (featuredImage || gallery) {
      const images = await upload(featuredImage, gallery);
      if (images) {
        if (images.featured_image) {
          values.featured_image = images.featured_image;
        }
        if (images.gallery_images) {
          values.gallery = images.gallery_images;
        }
      }
    }
    await updateData(lead._id, values);

    history.push(`/${LINK_URL}/${lead._id}/view`);
  };
  useEffect(() => {
    if (centre && centre.all_centres) {
      const newData = centre.all_centres.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, centre: newData });
    }
  }, [centre]);

  useEffect(() => {
    if (leadsource && leadsource.all_leadsources) {
      const newData = leadsource.all_leadsources.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, lead_source: newData });
    }
  }, [leadsource]);
  useEffect(() => {
    if (leadfeedbacktype && leadfeedbacktype.all_leadfeedbacktypes) {
      const newData = leadfeedbacktype.all_leadfeedbacktypes.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, lead_feedback_type: newData });
    }
  }, [leadfeedbacktype]);
  useEffect(() => {
    if (agent && agent.all_agents) {
      const newData = agent.all_agents.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, agent: newData });
    }
  }, [agent]);
  useEffect(() => {
    if (status && status.all_statuss) {
      const newData = status.all_statuss.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, status: newData });
    }
  }, [status]);
  useEffect(() => {
    if (product && product.all_products) {
      const newData = product.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, product: newData });
    }
  }, [product]);
  useEffect(() => {
    if (processingtype && processingtype.all_processingtypes) {
      const newData = processingtype.all_processingtypes.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, processing_type: newData });
    }
  }, [processingtype]);
  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, client: newData });
    }
  }, [client]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              lead && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {lead[Object.keys(inputFields)[0]]} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${lead._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={lead}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLead;
